
//这里是表模板的 更改编辑（tempData）数据的 vuex 管理器
//来自目录src\components\table_template_dir\table_template_main_tempData

export default {
	namespaced:true,
	state: {
		tempData: {
			multiplyActualPaths:'[]'
		}
	},
	getters: {
		
	},
	mutations: {
		changeTempData(state,payload){
			state.tempData = {...state.tempData,...payload} ;
		},
		emptyTempData(state){
			state.tempData = {
				multiplyActualPaths:'[]'
			};
		}
	},
	actions: {
		
	}
}