<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>

  export default{
	data(){
		return {
			
		}
	},
	methods:{
		
	}
  }
</script>

<style>
	
</style>
