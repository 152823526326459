import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//ui框架
	//这里是导入element ui
	import ElementUI from 'element-ui';
	import 'element-ui/lib/theme-chalk/index.css';
	//这里是导入muse-ui 	一个类似于element ui的东西
	import MuseUI from 'muse-ui';
	import 'muse-ui/dist/muse-ui.css';

	import axios from 'axios'

	


//文本编辑器
	//导入百度富文本编辑器
	import VueUeditorWrap from 'vue-ueditor-wrap';
	Vue.use(VueUeditorWrap)
	// Vue.component('vue-ueditor-wrap', VueUeditorWrap) 
	//导入quill模块 
	import VueQuillEditor from 'vue-quill-editor'
	import 'quill/dist/quill.core.css' // 引入样式
	import 'quill/dist/quill.snow.css' // snow theme
	import 'quill/dist/quill.bubble.css' // bubble theme
	Vue.use(VueQuillEditor)
	Vue.component('vue-quill-wrap', VueQuillEditor) 

	Vue.config.productionTip = false
	Vue.use(ElementUI)
	Vue.use(MuseUI)
	Vue.prototype.axios = axios
	Vue.prototype.$store = store 
	// Vue.prototype.LOCAL_URL = 'http://localhost:8080/'

	
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
